<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h5 class="m-0">Stores</h5>

                </div>
                <div class="col-auto">
                    <button class="btn btn-outline-success circle-30 " @click="showStores = !showStores">
                        <i class="fa fa-eye"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body animated fadeIn" v-show="showStores">
            <table class="table table-striped">
                <thead></thead>
                <tbody>
                    <tr v-for=" (store,index) in storeList" :key="index">
                        <td class="col">
                            <p v-text="store.name || store.id"></p>
                        </td>
                        <td>
                            <button class="btn btn-success btn-sm" @click="setStoreId(store.id)"> Select</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>
<script>
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex'
    export default {
        data() {
            return {
                showStores: true,
            }
        },
        computed: {
            ...mapState('EcartNewCart', ['newCart', 'storeList']),
        },
        methods: {
            // ...mapActions('EcartNewCart', ['fnApiCreateCart', 'fnApiGetCart','fnApiGetStores']),
            ...mapMutations('EcartNewCart', ['setStoreId']),
        },
    }
</script>